export const cardsData =
    [
        {
            frontTitle1: "ACCOMPAGNEMENTS",
            frontTitle2: "individuels",
            backTitle: "Accompagnements individuels",
            details: [
                "Jeunes",
                "Parents avec ou sans enfants (guidance parentale, thérapie familiale)",
                "Adultes",
            ],
        },
        {
            frontTitle1: "ACTIONS",
            frontTitle2: "collectives",
            backTitle: "Actions collectives",
            details: [
                "Groupes de parents",
                "Groupes parents/enfants",
                "Groupes d'élèves (primaire, collège, lycée)",
            ],
        },
        {
            frontTitle1: "SOUTIEN",
            frontTitle2: "professionnels",
            backTitle: "Soutien professionnels",
            details: ["Analyse de pratiques"],
        },
    ]
