<template>
  <div class="cards">
    <div class="card" v-for="card in cards" :key="card.frontTitle1">
      <div class="card-body">
        <div class="card-front">
          <div class="firstLine">{{ card.frontTitle1 }}</div>
          <div class="line"></div>
          <div class="secondLine">{{ card.frontTitle2 }}</div>
        </div>
        <div class="card-back">
          <p>{{ card.backTitle }}</p>
          <ul>
            <li v-for="detail in card.details" :key="detail">{{ detail }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cardsData } from "../data/cardsData";

export default {
  data: function () {
    return {
      cards: cardsData,
    };
  },
};
</script>

<style lang="scss">
@import "../style/MainStyle.scss";
</style>

