<template>
  <div class="header-container">
    <div class="title">FRASE<span class="dot">.</span></div>
    <div class="subtitle">
      <strong>F</strong>ormation <strong>R</strong>echerche
      <strong>A</strong>ccompagnement <strong>S</strong>outien
      <strong>E</strong>xpérience
    </div>

    <div class="link">
      <a
        :href="urlGoogleMap"
        target="_blank"
        matTooltip="Situez moi sur GoogleMap"
        matTooltipClass="tooltip"
        ><img src="../assets/img/map.jpg" alt="logo google Map"
      /></a>
      <a
        :href="urlFaceBook"
        target="_blank"
        matTooltip="Ma page Facebook"
        matTooltipClass="tooltip"
        ><img src="../assets/img/fb.jpg" alt="logo facebook"
      /></a>
      <a
        :href="urlPagesJaunes"
        target="_blank"
        matTooltip="Mon contact sur les Pages Jaunes"
        matTooltipClass="tooltip"
        ><img src="../assets/img/pj.jpg" alt="logo pages jaunes"
      /></a>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: "PageHeader",

  data: function () {
    return {
      urlGoogleMap:
        "https://www.google.com/maps/place/FRASE/@50.6457397,2.9985736,15z/data=!4m5!3m4!1s0x0:0x6ea800095b77a75e!8m2!3d50.6457397!4d2.9985736?hl=fr",
      urlPagesJaunes: "https://www.pagesjaunes.fr",
      urlFaceBook: "https://www.facebook.com/",
    };
  },
};
</script>


<style lang="scss">
@import "../style/HeaderStyle.scss";
</style>