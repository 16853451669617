<template>
  <div class="footer-container">
    <button class="button1">
      <div class="popover_content">mon text ici</div>
      <fa-icon icon="address-card" class="icon" /> Contact
    </button>
    <button class="button2" @click="isActive = !isActive">
      <div
        class="popover_content"
        :class="isActive ? 'show_popover_content' : ''"
      >
        <iframe
          src="https://maps.google.com/maps?q=frase%20208%20rue%20jules%20guesde%20lomme&t=&z=13&ie=UTF8&iwloc=&output=embed"
        ></iframe>
        <span>Nous sommes ici !</span>
        <br />
        <span style="color: #ff6550">208, rue Jules Guesde Lomme/Lille</span>
      </div>
      <fa-icon icon="house" class="icon" /> Siège social
    </button>
    <button class="button3">
      <div class="popover_content">mon text ici</div>
      <fa-icon icon="people-group" class="icon" /> Qui sommes nous ?
    </button>
    <button class="button4">
      <div class="popover_content">mon text ici</div>
      <fa-icon icon="sack-dollar" class="icon" /> Financements
    </button>
    <button class="button5">
      <div class="popover_content contact">Contactez Delphine au :</div>
      <fa-icon icon="phone" class="icon" /> 0695121019
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    toggleClassActive() {
      this.isActive = !this.isActive;
    },
  },
  data: () => ({
    isActive: false,
  }),
};
</script>

<style lang="scss">
@import "../style/FooterStyle.scss";
</style>