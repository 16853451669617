<template>
  <div id="app">
    <PageHeader></PageHeader>
    <MainPage></MainPage>
    <PageFooter></PageFooter>
  </div>
</template>

<script>
import PageHeader from "./components/header-component.vue";
import MainPage from "./components/main-component.vue";
import PageFooter from "./components/footer-component.vue";

export default {
  name: "App",
  components: {
    PageHeader,
    MainPage,
    PageFooter,
  },
};
</script>

<style>
@font-face {
  font-family: "Pecita";
  src: url("assets/fonts/Pecita.otf");
}
@import url(https://fonts.googleapis.com/css2?family=Roboto%3Awght%40300%3B400%3B700&display=swap);
body {
  margin: 0;
  padding: 0;
}
#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
